export const defaultPath = '/'

export const privatePaths = {
  superuser: {
    contactManager: '/superuser/contact_manager',
    sales: '/superuser/sales',
    gifts: '/superuser/gifts',
    giftDetails: '/superuser/gifts/:giftId',
    franchisee: '/superuser/franchisee',
    franchiseeCreate: '/superuser/franchisee/create',
    franchiseeDetail: '/superuser/franchisee/:franchiseeId/user/:userid',
    subfranchiseeDetail:
      '/superuser/franchisee/:franchiseeId/user/:userid/subfranschisees/:subFranID-:subFranUserID',
    subFranMerchantDetail:'/superuser/franchisee/:franchiseeId/user/:userid/subfranschisees/:subFranID-:subFranUserID/merchants/:merchantId',
    merchantDetail: '/superuser/franchisee/:franchiseeId/user/:userid/merchants/:merchantId',
    shoppersMembers: '/superuser/shoppers_members',
    merchants: '/superuser/merchants',
    merchantDetails: '/superuser/merchants/:merchantTab/:merchantId',
    profile: '/superuser/profile',
    appSettings: '/superuser/app_settings',
    contactManagerDetail: '/superuser/contact_manager/:userid',
    contactManagerMerchantDetail: '/superuser/contact_manager/:userid/merchants/:merchantId',
    contactManagerSubfranchiseeDetail: '/superuser/contact_manager/:userid/subfran/:subFranID-:subFranUserID',
    wallet: '/superuser/wallet',
    financials: '/superuser/financials',
    financialsDetail: '/superuser/financials/:userid/:walletID'
  },
  franchisee: {
    sub_franchisees: '/franchisee/sub_franchisees',
    sub_franchiseesCreate: '/franchisee/sub_franchisees/create',
    subFranchiseeMap: '/franchisee/sub_franchisees/map/:subFranchiseeId',
    merchant: '/franchisee/merchant',
    merchantDetails: '/franchisee/merchant/:merchantTab/:merchantId',
    subFranMerchantDetails: '/franchisee/sub_franchisees/:subFranID/user:subFranUserID/merchants/:merchantId',
    merchantGiftDetails:
      '/franchisee/merchant/:merchantTab/:merchantId/gifts/:giftId',
    merchantGifts: '/franchisee/merchant/:merchantTab/:merchantId/gifts',
    allGifts: '/franchisee/all_gifts',
    giftsMerchantDetails:
      '/franchisee/all_gifts/:giftsTab/merchant/:merchantId',
    giftsGiftDetails: '/franchisee/all_gifts/:giftsTab/gift/:allGiftsGiftId',
    promotionDetails: '/franchisee/all_gifts/promotions/:promotionId',
    shoppersMembers: '/franchisee/shoppers_members',
    pushNotifications: '/franchisee/push_notifications',
    pushNotificationsData:
      '/franchisee/push_notifications/:pushNotificationsTab/:notificationsDataId',
    massEmail: '/franchisee/mass_email',
    massEmailDetails: '/franchisee/mass_email/:massEmailTab/:massEmailDetailId',
    appSettings: '/franchisee/app_settings',
    subFranchisee: '/franchisee/sub_franchisees/:subFranID/user:subFranUserID',
    translations:'/franchisee/translations',
    wallet: '/franchisee/wallet',
    profile: '/franchisee/profile'
  },
  subfranchisee: {
    merchant: '/sub_franchisee/merchant',
    merchantDetails: '/sub_franchisee/merchant/:merchantTab/:merchantId',
    merchantGiftDetails:
      '/sub_franchisee/merchant/:merchantTab/:merchantId/gifts/:giftId',
    gifts: '/sub_franchisee/gifts/',
    giftDetails: '/sub_franchisee/gifts/:giftTab/:giftId',
    promotionDetails: '/sub_franchisee/gifts/promotions/:promotionId',
    shoppersMembers: '/sub_franchisee/shoppers_members',
    pushNotifications: '/sub_franchisee/push_notifications',
    pushNotificationsData:
      '/sub_franchisee/push_notifications/:pushNotificationsTab/:notificationsDataId',
    massEmail: '/sub_franchisee/mass_email',
    massEmailDetails:
      '/sub_franchisee/mass_email/:massEmailTab/:massEmailDetailId',
    profile: '/sub_franchisee/profile'
  },
  merchant: {
    businessInfo: '/merchant/business_info',
    fileFolder: '/merchant/file_folder',
    wallet: '/merchant/wallet',
    appSettings: '/merchant/app_settings',
    gifts: '/merchant/my_gifts',
    createPromo: '/merchant/my_gifts/promotions/create_promo/:merchantGiftId',
    createGift: '/merchant/my_gifts/:giftTab/create_gift',
    createGiftByPhotoId: '/merchant/file_folder/:photoId/create_gift',
    giftDetails: '/merchant/my_gifts/allGifts/:merchantGiftId',
    promotionDetails: '/merchant/my_gifts/promotions/:promotionId',
    giftClaim: '/merchant/claim_gift',
    profile:'/merchant/profile'
  },
  member: {
    profile: '/member/profile'
  },
  shopper: {
    profile: '/shopper/profile'
  },
  ogmember: {
    profile: '/ogmember/profile'
  },
  list: {
    lists: '/list/:id/:subid'
  }
}

export const publicPaths = {
  login: '/login',
  client_login: '/client-login',
  verify: '/verify/:verifyToken',
  reset_password: '/reset-password/:emailToken',
  lists: '/list/:id/:subid'
}
